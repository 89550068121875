import React, { useRef } from "react";
import moment from 'moment'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import parse from "html-react-parser"

import { sliderSettings } from '../const/attr'
import { labels } from '../langs/langs'

import starE from '../images/star-empty.svg'
import starF from '../images/star-filled.svg'

const ReviewSlider = ({ data, localeID }) => {

    const slider = useRef();

    const revs = data.nodes;

    const settings = {
        ...sliderSettings,
        autoplay: false,
        infinite: false,
        arrows: true,
        nextArrow: <div/>,
        prevArrow: <div/>,
        responsive: [
            {
                breakpoint: 1366,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1
                }
            },
        ]
    }

    const emptyMsg = <><h3 className="empty">{labels[localeID]["WEARESORRY"]}</h3><p className="empty">{labels[localeID]["NOTHINGNOW"]}</p></>;

    return (
        <div className="hPadSec infSliderCon">
            <div className="content infSlider cards">
                    {!!revs.length ? <Slider {...settings} ref={slider}>
                        {!!revs.length && revs.map((item, index)=>{

                                return (
                                    <a key={index} target="_blank" href="https://www.google.com/search?q=fresh+market&oq=fresh+market&aqs=chrome.0.69i59j46i175i199i433i512j0i512j46i175i199i512j46i10i175i199i512j69i60l3.6414j0j4&sourceid=chrome&ie=UTF-8#lrd=0x476c8edab7d61f1d:0x74b980f1b8c9372c,1,,,">
                                        <div className="store">
                                            <div className="center">{item.title.slice(0,1)}</div>
                                            <div className="center l">
                                                <div>
                                                    <h5>{item.title}</h5>
                                                    <p>{item.date/*moment().diff(moment(item.dateBasicFormat), 'months')*/}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="center l">
                                            {item.acfReviews.stars >= 1 ? <img src={starF}/> : <img src={starE}/>}
                                            {item.acfReviews.stars >= 2 ? <img src={starF}/> : <img src={starE}/>}
                                            {item.acfReviews.stars >= 3 ? <img src={starF}/> : <img src={starE}/>}
                                            {item.acfReviews.stars >= 4 ? <img src={starF}/> : <img src={starE}/>}
                                            {item.acfReviews.stars >= 5 ? <img src={starF}/> : <img src={starE}/>}
                                        </div>
                                        {parse(item.content)}
                                    </a>
                                )
                            })}
                        </Slider> : emptyMsg}
            </div>
        </div>
    )
}


export default ReviewSlider;
