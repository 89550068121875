import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Fade } from "react-awesome-reveal";

import parse from "html-react-parser"

import { labels, localeUrls } from '../langs/langs'

import barman from '../images/barman.png'
import musician from '../images/musician.png'
import farmer from '../images/farmer.png'

const ForSellers = ({ localeID }) => {

    return (
        <div id="forSeller" className="hPadSec concrete g">
            <img className="second" src={barman}/>
            <img className="third" src={musician}/>
            <img className="first" src={farmer}/>
            <div className="content">
                <p><span>{labels[localeID]["FOR_SELLERS"]}</span></p>
                <h2 className="b">{parse(labels[localeID]["BESELLER"])}</h2>
                <p>#trhovisko  #podujatia  #gastro  #predaj</p>
                <div>{parse(labels[localeID]["BESELLERCOMM"])}</div>
                <div className="buttonsCon sellers">
                    <Link className="tag button">{labels[localeID]["BECOMESELLER"]}</Link>
                    <Link className="tag unclicked button">{labels[localeID]["ORGEVENT"]}</Link>
                </div>
            </div>
        </div>    
    )
}

export default ForSellers;
