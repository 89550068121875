import React, { useRef } from "react";
import { useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"
import { Fade } from "react-awesome-reveal";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { sliderSettings1 } from '../const/attr'

import { labels, localeUrls } from '../langs/langs'

import ArrowLink, { rSVG } from "./ArrowLink";

import fbIcon from "../images/fb-g.svg"
import inIcon from "../images/ig-g.svg"
import fbIconW from "../images/fb-w.svg"
import inIconW from "../images/ig-w.svg"

const SocialSlider = ({ localeID, white=false }) => {

    const slider = useRef();

    const data = useStaticQuery(graphql`
        query SocialQuery {
            allWpPost(
                filter: {categories: {nodes: {elemMatch: {slug: {eq: "socialne-siete"}}}}}
                sort: { fields: [date], order: DESC }
                limit: 8
            ) {
                nodes {
                    ...PostSliderData
                    acfSocialNetworks {
                        socialnet
                    }
                }
            }
        }
    `)

    const feeds = data.allWpPost.nodes;

    const emptyMsg = <><h3 className="empty">{labels[localeID]["WEARESORRY"]}</h3><p className="empty">{labels[localeID]["NOTHINGNOW"]}</p></>;

    const settings = {
        ...sliderSettings1,
        slidesToShow: 6,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 2
                }
            },
        ]
    }

    return (
        <div className={"socFeeds hPadSec infSliderCon" + (white ? "" : "concrete")}>
            <div className="content infSlider cards">
                <h2>{parse(labels[localeID]["HSOCFEED"])}</h2>
                {!!feeds.length ? <Slider {...settings} ref={slider}>
                    {!!feeds.length && feeds.map((item, index)=>{
                            return (
                                <div key={index}>
                                    <GatsbyImage image={item?.featuredImage?.node?.localFile?.childrenImageSharp[0]?.gatsbyImageData} alt='test' />
                                    <a href={item.title} className="hover center"><img src={item.acfSocialNetworks.socialnet == 'fb' ? fbIconW : inIconW }/></a>
                                </div>
                            )
                        })}
                </Slider> : emptyMsg}
                <div className="center l">
                    <Fade>
                        <a target="_blank" className="aArow" href="https://www.instagram.com/freshmarketba/" color="#1D4B3A"><span className="center"><img src={inIcon}/>Instagram</span>{rSVG("#1D4B3A")}</a>
                        <a target="_blank" className="aArow" href="https://www.facebook.com/FarmarskaTrznica/" color="#1D4B3A"><span className="center"><img src={fbIcon}/>Facebook</span>{rSVG("#1D4B3A")}</a>
                    </Fade>
                </div>
            </div>
        </div>
    )
}

export default SocialSlider;
