import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import { Zoom, Fade } from "react-awesome-reveal";

import { labels, localeUrls } from '../langs/langs'
import { isBrowser } from '../functions/functions'

import ArrowLink from "./ArrowLink";
import PartnersSlider from "./PartnersSlider";
import IsometricMap from "./IsometricMap";
import ReviewSlider from "./ReviewSlider";
import EventSlider from "./EventSlider";
import SocialSlider from "./SocialSlider";
import NewsCon from "./NewsCon";
import ForSellers from "./ForSellers";

import wawyG from '../images/wavy4.svg'
import grainI from '../images/grain.svg'
import globeI from '../images/globe.svg'
import eventI from '../images/events.svg'

import marketI from '../images/market.svg'
import cuisineI from '../images/cuisine.svg'
import groceryI from '../images/grocery.svg'
import barsI from '../images/bars.svg'

import marketIG from '../images/market-green.svg'
import globeIG from '../images/globe-green.svg'
import vegeIG from '../images/vege-green.svg'
import eventsIG from '../images/events-green.svg'

import heroVideo from '../videos/hero.mp4'

const HomePage = ({ localeID, events, news, stores, reviews }) => {

    const heroVid = useRef();
    const [canPlay, setCanPlay] = useState(false);

    useEffect(()=>{
        if (isBrowser()) {
            function addAutoplay() {
                if(window.innerWidth >992){
                    heroVid?.current?.setAttribute("autoplay", true);
                    heroVid?.current?.play();
                };
            }

            window.onload = addAutoplay();
            window.onresize = addAutoplay;
        }
    },[])

    return (
        <>
            <div className="frontPage">
                <StaticImage src="../images/mobileBckg.jpg" alt="" placeholder="blurred"/>
                <video id="heroVid"
                    ref={heroVid}
                    playsInline
                    muted 
                    loop
                    preload="none"
                    onCanPlay={()=>setCanPlay(true)}
                >
                    <source src={heroVideo} type="video/mp4"/>
                </video>
                <div id="blendConB"/>
                <div id="heroVidB" className={(canPlay ? 'hidden' : '')}/>
                <div className="content center">
                    <div>
                        <p className="hComment">Fresh Market</p>
                        <h1 className="b">{parse(labels[localeID]["MAIN_HEAD"])}</h1>
                        <p className="text18 m">{parse(labels[localeID]["MAIN_TEXT"])}</p>
                        <a href="https://goo.gl/maps/a8yYwdtxWSW8ta9J7" target="_blank" className="cta g">{labels[localeID]["VISIT_US"]}</a>
                    </div>
                </div>
            </div>
            <div id="missionBanner" className="center">
                <div className="content grid3">
                    <div className="center">
                        <p className="center"><img src={grainI}/>{labels[localeID]["GRAINI"]}</p>
                    </div>
                    <div className="center">
                        <p className="center"><img src={globeI}/>{labels[localeID]["GLOBEI"]}</p>
                    </div>
                    <div className="center">
                        <p className="center"><img src={eventI}/>{labels[localeID]["EVENTSI"]}</p>
                    </div>
                </div>
                <img src={wawyG}/>
            </div>
            <div id="homeGrid" className="hPadSec concrete">
                <div className="content">
                    <h2 className="hideOnVerySmallScreen">{parse(labels[localeID]["HUONEROOF"])}</h2>
                    <div className="text18 hideOnVerySmallScreen">{labels[localeID]["HUONEROOFT"]}</div>
                    <div className="squareGrid">
                        <div className="center bckgImgCon">
                            <StaticImage src='../images/marketB.jpg' alt="" placeholder="blurred"/>
                            <div className="b"></div>
                            <div>
                                <Zoom><img src={marketI}/></Zoom>
                                <h3>{labels[localeID]["STORES_F"]}</h3>
                                <p>{parse(labels[localeID]["FARM_MARKET_I"])}</p>
                                <ArrowLink to={localeUrls[localeID]["STORES"] + '#' + localeUrls[localeID]["STORES_F"]} color="#ED6E47">{labels[localeID]["MORE"]}</ArrowLink>
                            </div>
                        </div>
                        <div className="center bckgImgCon">
                            <StaticImage src='../images/cuisineB.jpg' alt="" placeholder="blurred"/>
                            <div className="b"></div>
                            <div>
                                <Zoom><img src={cuisineI}/></Zoom>
                                <h3>{labels[localeID]["STORES_E"]}</h3>
                                <p>{parse(labels[localeID]["EXP_CUISINE_I"])}</p>
                                <ArrowLink to={localeUrls[localeID]["STORES"] + '#' + localeUrls[localeID]["STORES_E"]} color="#ED6E47">{labels[localeID]["MORE"]}</ArrowLink>
                            </div>
                        </div>
                        <div className="center bckgImgCon">
                            <StaticImage src='../images/groceryB.jpg' alt="" placeholder="blurred"/>
                            <div className="b"></div>
                            <div>
                                <Zoom><img src={groceryI}/></Zoom>
                                <h3>{labels[localeID]["STORES_G"]}</h3>
                                <p>{parse(labels[localeID]["GROCERY_I"])}</p>
                                <ArrowLink to={localeUrls[localeID]["STORES"] + '#' + localeUrls[localeID]["STORES_G"]} color="#ED6E47">{labels[localeID]["MORE"]}</ArrowLink>
                            </div>
                        </div>
                        <div className="center bckgImgCon">
                            <StaticImage src='../images/barsB.jpg' alt="" placeholder="blurred"/>
                            <div className="b"></div>
                            <div>
                                <Zoom><img src={barsI}/></Zoom>
                                <h3>{labels[localeID]["STORES_C"]}</h3>
                                <p>{parse(labels[localeID]["BARS_I"])}</p>
                                <ArrowLink to={localeUrls[localeID]["STORES"] + '#' + localeUrls[localeID]["STORES_C"]} color="#ED6E47">{labels[localeID]["MORE"]}</ArrowLink>
                            </div>
                        </div>
                    </div>
                    <Fade>
                        <ArrowLink to={localeUrls[localeID]["STORES"]} className="big" color="#4C9D34">{labels[localeID]["SHOWALLSTORES"]}</ArrowLink>
                    </Fade>
                </div>
            </div>
            <PartnersSlider localeID={localeID}/>
            <MapBanner>
                <div>{parse(labels[localeID]["MAPCOMM"])}</div>
                <Fade cascade={true} damping={0.1}>
                    <p>{parse(labels[localeID]["MAPCOMM5"])}</p>
                    <p className="center"><img src={marketIG}/><span>{parse(labels[localeID]["MAPCOMM1"])}</span></p>
                    <p className="center"><img src={globeIG}/><span>{parse(labels[localeID]["MAPCOMM2"])}</span></p>
                    <p className="center"><img src={vegeIG}/><span>{parse(labels[localeID]["MAPCOMM3"])}</span></p>
                    <p className="center"><img src={eventsIG}/><span>{parse(labels[localeID]["MAPCOMM4"])}</span></p>
                </Fade>
            </MapBanner>
            <div className="concrete">
                <IsometricMap localeID={localeID} data={stores}/>
                <ReviewSlider data={reviews} localeID={localeID}/>
            </div>
            <EventSlider localeID={localeID} data={events}/>
            <SocialSlider localeID={localeID}/>
            <NewsCon localeID={localeID} data={news}/>
            <div className="concrete">
                <ReviewSlider data={reviews} localeID={localeID}/>
            </div>
            <ForSellers localeID={localeID}/>
        </>
    )
}

export const MapBanner = ({ children }) => <div id="map" className="hPadSec bckgImgCon">
    <StaticImage objectFit="contain" objectPosition="left" className="hoverOff hideOnSmallScreen" src="../images/map.jpg" alt="" placeholder="blurred"/>
    <div className="content">
        <div></div>
        <div className="hPadSec">
            {children}
        </div>
    </div>
</div>

export default HomePage